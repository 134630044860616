import React, {useState} from 'react';
import SEO from '../components/seo';
import Modal from 'react-modal';
import Layout from '../components/layout';

import home from '../images/home.jpg';
import room from '../images/PLroom.jpg';
import one from '../images/DSC06141.jpg';
import two from '../images/DSC06142.jpg';
import three from '../images/DSC06144.jpg';
import four from '../images/DSC06145.jpg';
import five from '../images/DSC06150.jpg';
import six from '../images/DSC06158.jpg';
import seven from '../images/DSC06167.jpg';
import eight from '../images/IMG_5519.jpg';
import nine from '../images/9.jpg';
import ten from '../images/10.jpg';
import eleven from '../images/11.jpg';
import twelve from '../images/12.jpg';
import thirteen from '../images/13.jpg';
import fourteen from '../images/14.jpg';
import fifteen from '../images/15.jpg';
import sixteen from '../images/16.jpg';
import seventeen from '../images/17.jpg';
import eighteen from '../images/18.jpg';
import nineteen from '../images/19.jpg';
import twenty from '../images/20.jpg';
import twentyOne from '../images/21.jpg';

import '../components/layout.css';

const Gallery = () => {
  Modal.setAppElement (`#___gatsby`);

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.58)',
    },
    content: {
      position: 'relative',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '900px',
      maxHeight: '600px',
      margin: '32px auto',
      padding: 0,
      border: 0,
    },
  };
  const images = [
    home,
    room,
    two,
    four,
    five,
    six,
    seven,
    eight,
    one,
    three,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
    twentyOne,
  ];

  const [modalOpen, setModalOpen] = useState (false);
  const [currentImage, setCurrentImage] = useState (false);
  const closeModal = () => {
    setModalOpen (false);
  };
  const openModal = img => {
    setModalOpen (true);
    setCurrentImage (img);
  };

  return (
    <Layout>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Modal"
      >
        {
          <span>
            <div className="close" onClick={closeModal}>&#10005;</div>
            <div className="imgBox">
              <img alt="gallery" src={currentImage} />
            </div>
          </span>
        }
      </Modal>
      <SEO title="Het team" />
      <div className="gallery">
        {images.map (img => (
          <div className="galleryImg" key={img}>
            {<img alt="galleryImg" src={img} onClick={() => openModal (img)} />}
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Gallery;
